import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import './Chart.css'; 

const PieChart = ({ data, selectedSubType }) => {
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [processedOutputData, setProcessedOutputData] = useState([]);
  const [locations, setLocations] = useState([]);

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  // select
  const handleOptionClick = (location) => {
    handleLocationChange({ target: { value: location } });
    
  };

  // useEffect(() => {
  //   // Prepare data for the chart based on the selected location
  //   const locationData = selectedLocation === "all" ? data?.all : data?.[selectedLocation];
  //   const locations = Object.keys(data || {});
  //   setLocations(locations)
  //   // Prepare Google Chart data with combined label and value
  //   if (locationData) {

  //     const outputData = [
  //       ["Task", "Count"],
  //       ...Object.entries(locationData).map(([key, value]) => [`${key}: ${value}`, value])
  //     ];
  //     setProcessedOutputData(outputData);
  //   } else {
  //     setProcessedOutputData([]);
  //   }
  // }, [selectedLocation, data]);


  useEffect(() => {
    // Prepare data for the chart based on the selected location
    const locationData = selectedLocation === "All" ? data?.All : data?.[selectedLocation];
    const locations = Object.keys(data || {});
    locations.sort((a, b) => b - a)
    setLocations(locations);

    if (locationData) {
      const newChartData = [["Task", "Count"]];
      const formattedEntries = Object.entries(locationData).map(([key, value]) => [`${key}: ${value}`, value]);
      let index = 0;
      formattedEntries.sort((a, b) => b[1] - a[1]);
      // Interval logic to progressively add data
      const interval = setInterval(() => {
        if (index < formattedEntries?.length) {
          newChartData.push(formattedEntries[index]);
          setProcessedOutputData([...newChartData]); // Trigger re-render with the updated data
          index++;
        } else {
          clearInterval(interval); // Clear the interval once all data is added
        }
      }, 150); // Adjust the interval time as needed for speed

      // Cleanup on component unmount or when data changes
      return () => clearInterval(interval);
    } else {
      setProcessedOutputData([]); // Reset when locationData is not available
    }
  }, [selectedLocation, data]);



  // const options = {
  //   title: "My Daily Activities",
  //   pieHole: 0.4,
  //   is3D: true,
  //   pieStartAngle: 100,
  //   sliceVisibilityThreshold: 0.02,
  //   legend: {
  //     position: "bottom",
  //     alignment: "center",
  //     textStyle: {
  //       color: "#233238",
  //       fontSize: 14,
  //     },
  //   },
  //   colors: ["#dd0d37", "#4CAF50", "#15e3a2", "#0087ff", "#FF5733"],
  // };

  // const options = {
  //   title: "My Daily Activities",
  //   pieHole: 0.4,
  //   is3D: true,
  //   // pieStartAngle: 100,
  //   // sliceVisibilityThreshold: 0.02,
  //   legend: {
  //     position: "bottom",
  //     alignment: "center",
  //     textStyle: {
  //       color: "#233238",
  //       fontSize: 14,
  //     },
  //   },
  //   pieSliceText: "label-value", // Options: 'label', 'value', or 'label-value'
  //   pieSliceTextStyle: {
  //     color: "#000",
  //     fontSize: 12,
  //   },
  //   colors: ["#dd0d37", "#4CAF50", "#15e3a2", "#0087ff", "#FF5733"],
  // };

  const TitleFormat = {
    "job_level": "Database Reach By Job Level",
    "employee_size": "Database Reach By Employee Size",
    "industry": "Database Reach By Industries"
  }

  const options = {
    title: TitleFormat[selectedSubType],
    // pieHole: 0.4,
    is3D: true,
    sliceVisibilityThreshold: 0,
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        color: "#233238",
        fontSize: 14,
      },
    },
    pieSliceText: "value",
    pieSliceTextStyle: {
      color: "#000",
      fontSize: 12,
    },
    onHover: function (event, legendItem) {
      var me = this;
      var options = me.options || {};
      var hoverOptions = options.hover;
      var index = legendItem.datasetIndex;
      var ci = this.chart;
      var elements = ci.getDatasetMeta(index).data;
      ci.updateHoverStyle(elements, hoverOptions.mode, true)
      ci.render();
    },
    // pieSliceText: "value",
    pieSliceTextStyle: {
      color: "white",
      fontSize: 12,
    },
  }
  return (
    <div className="pieChart" style={{position:"relative"}}>
      {Array.isArray(processedOutputData) && processedOutputData.length > 0 && <Chart
        chartType="PieChart"
        data={processedOutputData}
        // loader={<div>Loading Chart</div>}
        options={options}
        width={"100%"}
        height={"300px"}


      />}
      <div className="geo-dropdown"
      >
       <div
  style={{
    position: "relative",
    width: "200px", // Adjust width as needed
  }}
>
  {/* <div
    onClick={toggleDropdown}
    style={{
      marginTop: "45px",
      padding: "8px 12px",
      borderRadius: "6px",
      border: "1px solid #ccc",
      backgroundColor: "#fff",
      fontSize: "14px",
      color: "#333",
      cursor: "pointer",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    }}
  >
    {selectedLocation ? selectedLocation.toUpperCase() : "Select Location"}
  </div> */}

 
    <div >
      {/* Label for Geo Location */}
      <label
        htmlFor="geo-location-select"
        style={{
          
          display: "block",
          fontWeight: "bold",
          // marginBottom: "8px",
          fontSize: "13px",
          color: "#333",
        }}
      >
        Geo Locations
      </label>

      {/* Dropdown Select */}
      <select 
        id="geo-location-select"
        onChange={(e) => {
          handleOptionClick(e.target.value); 
        }}
        style={{
        
          padding: "2px 12px",
          borderRadius: "6px",
          border: "1px solid rgb(235, 87, 43)",
          backgroundColor: "#fff",
          fontSize: "12px",
          color: "#333",
          cursor: "pointer",
          outline:"none",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        value={selectedLocation}
      >
        {/* <option value="" disabled selected>
          Select a Location
        </option> */}
        {locations.map((location) => (
          <option key={location} value={location}>
            {location.toUpperCase()}
          </option>
        ))}
      </select>
    </div>

</div>

      </div>
    </div>
  );
};

export default PieChart;
