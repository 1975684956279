import React, { useEffect, useState } from "react";
// import "./BuildMyCampaign.scss";
import "./TableChart.scss";
import { isEmptyArray } from "formik";

const TableChart = ({ tableRow }) => {
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    if (Array.isArray(tableRow) && tableRow?.length > 0) {
      const headerKeys = Object.keys(tableRow[0]);
      setHeaders(headerKeys);
    }
  }, [tableRow]);
  // console.log("tableRow",tableRow)
  return (
    <>
<div
  className="row d-flex justify-content-center table-width"
  style={{ flexWrap: "nowrap" }}
>
  {/* Custom Table Wrapper with Scroll */}
  <div className="table-container">
    <table className="col-auto table table-warning m-auto ms-2 ms-md-5 text-center customTable">
      {/* Table Header */}
      <thead>
        <tr >
          {Array.isArray(headers) &&
            headers?.length > 0 &&
            headers.map((ele, index) => {
              return (
                <th
                  style={{
                    color: "#fff",
                    background: "linear-gradient(to right,rgba(204, 24, 11, 0.92),rgb(252, 122, 71))",
                  }}
                  key={index}
                  scope="col"
                  className={`p-2 ${
                    index === 0 ? "tbhead" : index === 1 ? "" : ""
                  } text-center-camapaign-table font-bold-camapaign-table`}
                >
                  {ele}
                </th>
              );
            })}
        </tr>
      </thead>

      {/* Table Body */}
      <tbody className="trhead">
        {Array.isArray(tableRow) &&
          tableRow.length > 0 &&
          tableRow.map((obj, item) => {
            // Calculate lighter shades of orange for each row, starting from a darker shade
            const gradientStart = `hsl(30, 100%, ${60 + item * 5}%)`; // Darker shade of orange for the first row
            const gradientEnd = `hsl(30, 100%, ${70 + item * 5}%)`; // Slightly lighter shade of orange

            return (
              <tr key={item}>
                {headers.length > 0 &&
                  headers.map((ele, index) => (
                    <th
                      key={index}
                      style={{
                        background: `linear-gradient(to right, ${gradientStart}, ${gradientEnd})`,
                      }}
                      scope={index === 0 ? "row" : null}
                      className={`p-2 ${index === 0 ? "tbhead" : ""} text-center-camapaign-table`}
                    >
                      {obj[ele]}
                    </th>
                  ))}
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
</div>
  {/* trial table */}
      {/* <div className="row mx-0 px-0 w-100" style={{ flexWrap: "nowrap" }}>
        <div className="col-12 m-auto text-center d-flex align-items-center justify-content-center">
          <div
            className="table-container"
            style={{ overflowX: "auto", overflowY: "auto", maxHeight: "200px" }}
          >
            <table className="main-table">
              <thead>
                <tr>
                  {Array.isArray(headers) &&
                    headers?.length > 0 &&
                    headers.map((ele, index) => {
                      return (
                        <th key={index} scope="col" className="th text-center">
                          {ele}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody className="tbody">
                {Array.isArray(tableRow) &&
                  tableRow.length > 0 &&
                  tableRow.map((obj, item) => {
                    return (
                      <tr className="tr" key={item}>
                        {headers.length > 0 &&
                          headers.map((ele, index) => (
                            <th
                              key={index}
                              scope={index === 0 ? "row" : null}
                              className={`${
                                index === 0 ? "th" : ""
                              } text-center-camapaign-table ddd`}
                            >
                              {obj[ele]}
                            </th>
                          ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>  */}
      {/* trial table */}
    </>
  );
};

export default TableChart;
